/** @jsx jsx */
import React from 'react';
import css from '@emotion/css';
import { jsx } from '@emotion/core';
import { ActionButton } from '../../components/basic/ActionButton';
import { sendPasswordResetEmail } from '../../api';
import { ButtonColor } from "react-magma-dom";

export interface ResendPasswordResetBtnProps {
  username: string;
}

export const ResendPasswordResetBtn: React.FC<ResendPasswordResetBtnProps> = props => {
  return (
    <ActionButton color={ButtonColor.secondary}
      block
      css={css`
        font-weight: 400;
        text-transform: none;
      `}
      toastOnFailure="Email sending error"
      toastOnSuccess="Email sent!"
      onAction={() => sendPasswordResetEmail(props.username)}
      onActionChildren="Sending..."
      // This would be a great situation for toastOnFailure, but Okta's styling is
      // currently too strong... once Widget gets reskinned and the styling is
      // relaxed, consider updating to utilize toastOnFailure.
    >
      RESEND PASSWORD RESET EMAIL
    </ActionButton>
  );
};