import React from 'react';
import { FacultyDataKeys } from '../../screens/Profile/FacultyDataKeys';
import { PhoneNumberInputProps, PhoneNumberInput } from './PhoneNumberInput';
import { isPhoneContainsNumbersOnly, ValidatorResponse, phoneLength } from './Validator';

export const TEST_ID = { MAIN: 'input-supervisor-phone' };

export interface SupervisorPhoneNumberInputProps
  extends PhoneNumberInputProps {
}

export const SupervisorPhoneNumberInput: React.FC<SupervisorPhoneNumberInputProps> = props => {
  return (
    <PhoneNumberInput
      // Default properties get passed through
      {...props}
      helperMessage={`10-${phoneLength} digits only, no symbols or special characters`}
      validator={async (value: string) => {
        const isValid: ValidatorResponse = isPhoneContainsNumbersOnly(value);
        if (!isValid.response) {
          throw new Error(isValid.reason);
        }
      }}
    />
  );
};

SupervisorPhoneNumberInput.defaultProps = {
  dataLabel: 'Supervisor Phone Number',
  name: FacultyDataKeys.supervisorPhone,
  testId: TEST_ID.MAIN,
  id: 'supervisor-phone-number-input',
  required: true
};
