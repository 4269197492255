import React from 'react';
import { loginLabelLower } from '../../basic/LoginLabel';

const minLength = 1;
const maxLength = 50;
const emailMinLength = 6;
export const emailMaxLength = 100;
const thisYear = new Date().getFullYear();
const yearMinLength = 4;
const youngestYear = thisYear - 13;
// As of edit, oldest person alive was born in 1903
const oldestYear = 1903;
const digitLength = 10;
export const phoneLength = 14;
export const minNameLengthReason = 'The provided value is not long enough.';
export const maxNameLengthReason = 'The provided value is too long.';
export const maxLoginLengthReason = `The provided ${loginLabelLower} is too long.`;
export const minLoginLengthReason = `The provided ${loginLabelLower} is not long enough.`;
export const duplicateLoginReason = (
  <>
    An account already exists with this username. If this is your account, <a href="/login">try signing in</a>.
  </>
);
export const maxEmailLengthReason = `The provided email is too long.`;
export const minEmailLengthReason = `The provided email is not long enough.`;
export const invalidCharactersReason = `Must use valid characters for username.`;
export const invalidEmailCharactersReason = `Must use valid characters for email.`;
export const internalEmailReason = `Internal user accounts will be automatically created on first sign in.  Please sign in.`;
export const invalidBirthYearReason = 'Expected full year, YYYY.';
export const invalidFormatReason = 'Value must be a number.';
export const invalidYearReason = `Must be between ${oldestYear} and ${youngestYear}`;
export const invalidPhoneReason = `Must be ${digitLength} digit phone number.`;
export const invalidPhoneReason_v1 = `Must use 10-${phoneLength} digits only, no symbols or special characters`;
export const invalidStandardInputReason = `Must be between ${minLength} and ${maxLength} characters.`;
const emailMatcher = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// Either 10 digit with hyphens or 10 digit without
// \d{3}-\d{3}-\d{4}
// \d{10}
const phoneFormat = /^((\d{3}-\d{3}-\d{4})|(\d{10}))$/;
const LETTER = 'A-Za-z';
const DIGIT = '0-9';
const SPECIAL_CHARS = "!#$%&'*+/=?^_`{|}~-";
const OKTA_EMAIL_REGEX = new RegExp(
  '^[' +
    LETTER +
    DIGIT +
    SPECIAL_CHARS +
    ']+' +
    '(?:\\.[' +
    LETTER +
    DIGIT +
    SPECIAL_CHARS +
    ']+)*' +
    '@' +
    '(?:[' +
    LETTER +
    DIGIT +
    ']' +
    '(?:[' +
    LETTER +
    DIGIT +
    '-]*[' +
    LETTER +
    DIGIT +
    '])?' +
    '\\.' +
    ')+' +
    '[' +
    LETTER +
    ']{2,20}$'
);

const SECONDARY_USERNAME_REGEX = new RegExp('^[^?&=#%<>/"|{}\\[\\]*^\\s]+$');

const safeParse = (value: string) => {
  try {
    const result = parseInt(value, 10);
    if (isNaN(result)) {
      throw new Error('Not a number');
    }
    return result;
  } catch (ignored) {
    console.log(`Could not parse value ${value}`);
    return null;
  }
};

export interface ValidatorResponse {
  response: boolean;
  reason?: string;
}

export const isFirstNameValid = (value: string): ValidatorResponse => {
  if (minLength > value.trim().length) {
    return {
      response: false,
      reason: minNameLengthReason
    };
  }
  if (maxLength < value.length) {
    return {
      response: false,
      reason: maxNameLengthReason
    };
  }
  return {
    response: true
  };
};

export const isLastNameValid = (value: string): ValidatorResponse => {
  if (minLength > value.trim().length) {
    return {
      response: false,
      reason: minNameLengthReason
    };
  }
  if (maxLength < value.length) {
    return {
      response: false,
      reason: maxNameLengthReason
    };
  }
  return {
    response: true
  };
};

export const isPreferredNameValid = (value: string): ValidatorResponse => {
  if (maxLength < value.trim().length) {
    return {
      response: false,
      reason: maxNameLengthReason
    };
  }
  return {
    response: true
  };
};

export const isAlternateLoginValid = (value: string): ValidatorResponse => {
  if (value === null) {
    return {
      response: true
    };
  }
  if (emailMaxLength < value.length) {
    return {
      response: false,
      reason: maxEmailLengthReason
    };
  }
  if (value.length !== 0 && emailMinLength >= value.length ) {
    return {
      response: false,
      reason: minEmailLengthReason
    };
  }

  if (value.length !== 0 && !emailMatcher.test(value)){
    return {
      response: false,
      reason: invalidEmailCharactersReason
    };
  }
  return {
    response: true
  };
};

export const isValidEmail = (value: string): ValidatorResponse => {
  if (emailMaxLength < value.length) {
    return {
      response: false,
      reason: maxEmailLengthReason
    };
  }
  if (emailMinLength > value.length) {
    return {
      response: false,
      reason: minEmailLengthReason
    };
  }
  if (!OKTA_EMAIL_REGEX.test(value)){
    return {
      response: false,
      reason: invalidEmailCharactersReason
    };
  }
  if (value.endsWith('@cengage.com') || value.endsWith('@contractor.cengage.com')){
    return {
      response: false,
      reason: internalEmailReason
    };
  }
  return {
    response: true
  };
};

export const isValidUserName = (value: string): ValidatorResponse => {
  if (maxLength < value.length) {
    return {
      response: false,
      reason: maxLoginLengthReason
    };
  }
  if (minLength > value.length) {
    return {
      response: false,
      reason: minLoginLengthReason
    };
  }
  
  if (!SECONDARY_USERNAME_REGEX.test(value)){
    return {
      response: false,
      reason: invalidCharactersReason
    };
  }
  if (value.endsWith('@cengage.com') || value.endsWith('@contractor.cengage.com')){
    return {
      response: false,
      reason: internalEmailReason
    };
  }
  return {
    response: true
  };
};

export const isBirthYearValid = (value: string): ValidatorResponse => {
  const asNumber = safeParse(value);
  if (null === asNumber) {
    return {
      response: false,
      reason: invalidFormatReason
    };
  }
  if (yearMinLength !== value.length) {
    return {
      response: false,
      reason: invalidBirthYearReason
    };
  }
  if (oldestYear > asNumber || youngestYear < asNumber) {
    return {
      response: false,
      reason: invalidYearReason
    };
  }
  return {
    response: true
  };
};

export const isInstitutionCityValid = (value: string): ValidatorResponse => {
  if (minLength > value.trim().length) {
    return {
      response: false,
      reason: minNameLengthReason
    };
  }
  if (maxLength < value.length) {
    return {
      response: false,
      reason: maxNameLengthReason
    };
  }
  return {
    response: true
  };
};

export const isInstitutionNameValid = (value: string): ValidatorResponse => {
  if (minLength > value.trim().length) {
    return {
      response: false,
      reason: minNameLengthReason
    };
  }
  if (maxLength < value.length) {
    return {
      response: false,
      reason: maxNameLengthReason
    };
  }
  return {
    response: true
  };
};

export const isInstitutionStateValid = (value: string): ValidatorResponse => {
  if (minLength > value.trim().length) {
    return {
      response: false,
      reason: minNameLengthReason
    };
  }
  if (maxLength < value.length) {
    return {
      response: false,
      reason: maxNameLengthReason
    };
  }
  return {
    response: true
  };
};
export const isPhoneContainsNumbersOnly = (value: string): ValidatorResponse => {
  if (value.length < 10 || value.length >14  || isNaN(Number(value))) {
    return {
      response: false,
      reason: invalidPhoneReason_v1
    };
  }
  return {
    response: true
  };
};

export const isPhoneValid = (value: string): ValidatorResponse => {
  if (!phoneFormat.test(value)) {
    return {
      response: false,
      reason: invalidPhoneReason
    };
  }
  return {
    response: true
  };
};

export const isSupervisorNameValid = (value: string): ValidatorResponse => {
  if (minLength > value.trim().length) {
    return {
      response: false,
      reason: minNameLengthReason
    };
  }
  if (maxLength < value.length) {
    return {
      response: false,
      reason: maxNameLengthReason
    };
  }
  return {
    response: true
  };
};

export const isValidStandardInput = (value: string): ValidatorResponse => {
  if (minLength > value.trim().length || maxLength < value.length) {
    return {
      response: false,
      reason: invalidStandardInputReason
    };
  }
  return {
    response: true
  };
};